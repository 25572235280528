/* Reset and basic styling */
body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  background-color: #121212;
  color: #e0e0e0;
}

/* Dodavanje zelenih okvira iznad i ispod headera */
.header-wrapper {
  border-top: 2px solid #00ffcc; /* Zeleni okvir iznad headera */
  border-bottom: 2px solid #00ffcc; /* Zeleni okvir ispod headera */
}

header {
  background-color: #ffffff; /* Bijela pozadina za header */
  padding: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Blaga sjenka za header */
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background-color: #ffffff; /* Bijela pozadina za navbar */
}

.navbar-collapse {
  background-color: #ffffff; /* Osigurava bijelu pozadinu kada je meni otvoren */
}

.navbar-nav {
  margin-left: auto;
  text-align: right;
}

.nav-item {
  margin-left: 0;
}

.navbar-collapse.show {
  display: block;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links li a {
  color: #000000; /* Crni linkovi */
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.nav-links li a:hover {
  color: #555555; /* Tamnija nijansa na hover */
  text-shadow: 0px 0px 5px #555555;
}

/* Stilizovanje za pozadinski video */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.hero {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  color: #00ffcc; /* Promjena boje teksta u zeleni */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.hero-content h1 {
  font-size: 64px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-shadow: 0 0 20px #00ffcc, 0 0 30px #00ffcc, 0 0 40px #00ffcc;
  font-family: 'Orbitron', sans-serif; /* Futuristički font */
}

.hero-content p {
  font-size: 24px;
  font-weight: 300;
  text-shadow: 0 0 10px #00ffcc, 0 0 20px #00ffcc;
  font-family: 'Orbitron', sans-serif; /* Futuristički font */
}

.container-fluid {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 50px;
  margin-right: 20px;
}

.services {
  padding: 50px 0;
  background-color: #1a1a1a;
  text-align: center;
  color: #00ffcc;
}

.services h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 0 20px;
}

.service-card {
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 255, 204, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 255, 204, 0.5);
}

.service-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ff00ff;
}

.about {
  padding: 50px 20px;
  background-color: #ffffff; /* Bijela pozadina za About Us sekciju */
  text-align: center;
  color: #000000; /* Crni tekst */
  border-top: 2px solid #00ffcc;
  border-bottom: 2px solid #00ffcc;
}
.logo {
  max-height: 50px;
  margin-right: 20px;
  /* Dodavanje zelene sjene oko logo slike */
  filter: drop-shadow(0 0 20px #00ffcc) drop-shadow(0 0 30px #00ffcc) drop-shadow(0 0 40px #00ffcc);
}

.contact {
  padding: 50px 20px;
  background-color: #2b2b2b;
  text-align: center;
  color: #00ffcc;
}

footer {
  background-color: #1f1f1f;
  color: #00ffcc;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
  border-top: 2px solid #00ffcc;
}

/* Media Queries */

/* Tablets (768px - 1024px) */
@media (max-width: 1024px) {
  .container-fluid {
    width: 90%;
  }
  .logo {
    max-height: 50px;
    margin-right: 20px;
    /* Dodavanje zelene sjene oko logo slike */
    filter: drop-shadow(0 0 20px #00ffcc) drop-shadow(0 0 30px #00ffcc) drop-shadow(0 0 40px #00ffcc);
  }
  .nav-links {
    flex-direction: column;
    margin-top: 10px;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .hero-content h1 {
    font-size: 48px;
  }

  .hero-content p {
    font-size: 20px;
  }

  .service-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .service-card h3 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%; /* Osigurava da navbar pokrije čitav ekran */
    background-color: #ffffff; /* Pozadina za navbar na mobilnim uređajima */
  }
  .logo {
    max-height: 50px;
    margin-right: 20px;
    /* Dodavanje zelene sjene oko logo slike */
    filter: drop-shadow(0 0 20px #00ffcc) drop-shadow(0 0 30px #00ffcc) drop-shadow(0 0 40px #00ffcc);
  }
  
  .navbar-nav {
    flex-direction: column;
    align-items: flex-end; /* Poravnanje stavki udesno */
    padding-right: 20px; /* Dodano za malo prostora s desne strane */
  }

  .nav-link {
    color: #000000; /* Crni linkovi za mobilne uređaje */
    padding: 10px 20px; /* Dodaje padding za bolju čitljivost */
    width: 100%; /* Osigurava da linkovi zauzimaju punu širinu */
    text-align: right; /* Tekst linkova poravnan udesno */
  }

  .nav-link:hover {
    color: #555555;
    text-shadow: 0px 0px 5px #555555;
  }

  .container-fluid {
    width: 100%;
    padding: 0 10px;
  }

  .hero {
    height: 30vh;
  }

  .video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100vw;
    min-height: 100vh;
    transform: translate(-50%, -50%);
    object-fit: cover;
    pointer-events: none;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 18px;
  }
}
